// Here you can add other styles
.footer {
  justify-content: center;
  font-size: 0.9rem;
}

.pagination {
  justify-content: right;
}

.pagination button {
  margin-right: 5px;
}

.pagination select {
  width: 100px;
}

.table input {
  width: 5rem;
  border-radius: 0.3rem;
  padding: 5px;
  border: 1px solid #ced4da;
  height: 25px;
  font-size: 0.7rem;
}

.summary-report-table th:nth-child(4), .summary-report-table th:nth-child(5), .summary-report-table th:nth-child(6), .summary-report-table th:nth-child(7), .summary-report-table th:nth-child(8), .summary-report-table th:nth-child(9), .summary-report-table th:nth-child(10), .summary-report-table th:nth-child(11) {
  text-align: right;
}

.me-report-table th:nth-child(2), .me-report-table th:nth-child(3), .me-report-table th:nth-child(4), .me-report-table th:nth-child(5) {
  text-align: right;
}

.report-table th:nth-child(5), .report-table th:nth-child(6), .report-table th:nth-child(7), .report-table th:nth-child(8), .report-table th:nth-child(9) {
  text-align: right;
}

.text-link 
{
  color: #39f;
}

.text-link:hover
{
  color: #39f;
  text-decoration: none;
}

.table input[type='checkbox'] {
  width: 16px;
  height: 16px;
}

.table select {
  width: 5rem;
  border-radius: 0.3rem;
  padding: 0px;
  border: 1px solid #ced4da;
  height: 25px;
  font-size: 0.7rem;
}

.table thead th span {
  font-weight: 600;
}

.table td {
  font-size: 0.8rem;
}

.td-middle td {
  vertical-align: middle !important;
}

.form-check-label {
  font-size: 0.8rem;
}

.form-label {
  font-size: 0.9rem;
  font-weight: 600;
}

.custom_label {
  font-size: 0.875rem;
}

th {
  font-size: 0.8rem;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.dashboard-card-txt {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 4rem;
  height: 10rem;
}

.dashboard-card {
  margin-bottom: 0rem;
}

.gray-bg
{
  background-color: #f8f8f8;
  border-radius: 0.2rem;
}

.lblue-bg
{
  background-color: #e9f4fc;
  border-radius: 0.2rem;
}

.second-col{
  padding-left: 2rem;
}

.inline-calander
{
  width: 6rem !important;
}

.select-search-input
{
  padding: 0.25rem 0.5rem !important;
  border-radius: 0.2rem !important;
  height: calc(1.5em + 2px + 0.5rem) !important;
  width:100% !important;
  border:1px solid var(--cui-input-border-color, #b1b7c1) !important;
  font-size: 0.875rem !important;
  color: rgba(44, 56, 74, 0.95) !important;
  font-family: inherit !important;
}

.dashboard-item-left-col
{
  background-color: #f2f2f2; 
  border-top-left-radius:0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.dashboard-item-left-col:hover
{
  cursor: pointer;
}

.dashboard-item-right-col
{
  background-color: #f2f2f2; 
  border-top-right-radius:0.2rem;
  border-bottom-right-radius: 0.2rem;
  padding-top: 12px;
  text-align: right;
}

.dashboard-item-right-col:hover
{
  cursor: pointer;
}

.dashboard-item-heading
{
  vertical-align: middle;
  padding-top: 7px;
  font-size: 1.1rem;
}

.dashboard-block
{
  border:1px rgb(30, 99, 190) solid; 
  border-radius:0.2rem;
  background-color: rgb(30, 99, 190);
  color:white;
  text-align: center;
  padding-top: 0.5rem;
}

.custom-page-block
{
  background-color:#fafafa;
  padding:8px;
  border-radius:0.3rem;
}

@media only screen and (max-width: 768px) {
  .dashboard-card {
    margin-bottom: 1rem;
  }

  .second-col{
    padding-left: calc(var(--cui-gutter-x) * 0.5);
  }
}
